<template>
  <div id="user-list">
    <!-- app drawer -->
    <user-list-add-new
      v-model="isAddNewUserSidebarActive"
      :login-methods="loginMethods"
      @refetch-data="fetchUsers"
    ></user-list-add-new>

    <!-- user total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in userTotalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveUserTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveUserTotalIcon(total.title).color}--text`"
            >
              <v-icon
                size="25"
                :color="resolveUserTotalIcon(total.title).color"
                class="rounded-0"
              >
                {{ resolveUserTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- table -->
    <v-card>
      <v-card-title>
        Leerkrachten
      </v-card-title>
      <!-- Login method filter -->
      <!-- <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="loginMethodFilter"
            placeholder="Kies inlogmethode"
            :items="loginMethods"
            item-text="title"
            item-value="value"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col>
      </v-row> -->

      <!-- <v-divider class="mt-4"></v-divider> -->

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Zoeken"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Leerkracht toevoegen</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="userListTable"
        :loading="loading"
        loading-text="Leerkrachten aan het laden..."
        no-data-text="Geen leerkrachten gevonden"
        show-select
      >
        <!-- name -->
        <template #[`item.firstName`]="{item}">
          <div class="d-flex align-center">
            <!-- Avatar -->
            <!-- Avatar -->
            <v-avatar
              color="primary"
              class="v-avatar-light-bg primary--text"
              size="32"
            >
              <span
                class="font-weight-semibold"
              >{{ avatarText(item.firstName + ' ' + item.lastName.split(' ')[0]) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'users-teachers-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.firstName + ' ' + item.lastName }}
              </router-link>
              <small>@{{ item.firstName }}</small>
            </div>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'users-teachers-view',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="onBtnDeleteTeacherClick(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Verwijderen</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <!-- Delete dialog -->
    <v-dialog
      v-if="deleteTeacher.showDeleteDialog"
      v-model="deleteTeacher.showDeleteDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          Leerkracht verwijderen
        </v-card-title>

        <v-card-text>
          Ben je zeker dat je {{ deleteTeacher.teacherToDelete.firstName }} {{ deleteTeacher.teacherToDelete.lastName }} wil verwijderen? Deze actie kan niet ongedaan gemaakt worden. Ook alle gekoppelde inloggegevens zullen hiermee verwijderd worden.
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="secondary"
            @click="deleteTeacher.showDeleteDialog = false"
          >
            Annuleren
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="onBtnDeleteTeacherConfirm"
          >
            Verwijderen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiFileDocumentOutline, mdiPlus, mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import axios from '@axios'
import { avatarText } from '@core/utils/filter'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'

import useUsersList from './useUsersList'

export default {
  components: {
    UserListAddNew,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const isDialogVisible = ref(false)

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const {
      userListTable,
      tableColumns,
      searchQuery,
      loginMethodFilter,
      planFilter,
      statusFilter,
      totalUserListTable,
      loading,
      options,
      userTotalLocal,
      selectedRows,

      fetchUsers,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserTotalIcon,
    } = useUsersList()

    const isAddNewUserSidebarActive = ref(false)

    // Login methods
    const loginMethods = ref([])
    axios.get('/loginmethod').then(response => {
      loginMethods.value = response.data.loginMethods
    })

    // Leerkracht verwijderen
    const deleteTeacher = ref({
      showDeleteDialog: false,
      teacherToDelete: {},
    })

    function onBtnDeleteTeacherClick(teacher) {
      deleteTeacher.value.showDeleteDialog = true
      deleteTeacher.value.teacherToDelete = teacher
    }

    function onBtnDeleteTeacherConfirm() {
      console.log(deleteTeacher.value.teacherToDelete.id)
      store.dispatch('app-user/deleteUser', deleteTeacher.value.teacherToDelete).then(() => {
        deleteTeacher.value.showDeleteDialog = false
        fetchUsers()
      })
    }

    return {
      deleteTeacher,
      onBtnDeleteTeacherClick,
      onBtnDeleteTeacherConfirm,
      isDialogVisible,
      userListTable,
      tableColumns,
      searchQuery,
      loginMethodFilter,
      planFilter,
      statusFilter,
      totalUserListTable,
      loginMethods,
      loading,
      options,
      userTotalLocal,
      isAddNewUserSidebarActive,
      selectedRows,
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserTotalIcon,
      fetchUsers,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
